import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/global/layout"
import PrejudiceBanner from "../components/prejudice/banner"
import PrejudiceFiles from "../components/prejudice/files"
import PrejudiceList from "../components/prejudice/list"
import PrejudiceStories from "../components/prejudice/stories"
import SEO from "../components/seo/seo"

export default ({ data }) => {
  const {siteUrl} = data.site.siteMetadata
  const {
    info,
    list,
    stories_title
  } = data.pagesJson
  const images = data.allFile.edges
  images.sort((imageA, imageB) => {
    if (imageA.node.childImageSharp.fluid.originalName < imageB.node.childImageSharp.fluid.originalName) {
      return -1;
    }
    return 1;
  })

  return (
    <Layout page='prejudice'>
      <div className='prejudice-page'>
        <SEO title={`Sont-ils ce que vous pensez ?`} url={`${siteUrl}prejuges/`}/>
        <PrejudiceBanner image={data.bannerImg} info={info}/>
        <PrejudiceList images={images} list={list}/>
        <PrejudiceFiles logoMikana={data.logoMikana} />
        <PrejudiceStories title={stories_title}/>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query {
    site {
      siteMetadata {
        siteUrl,
      }
    },
    pagesJson(id: { eq: "prejudice" }) {
      info,
      list {
        info,
        title
      },
      stories_title
    },
    allFile(filter:{ relativePath: { regex: "/^images/prejudice/list/" } }) {
      edges {
        node {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid,
              originalName,
            }
          }
        }
      }
    },
    bannerImg: file(name: { eq: "prejudice-banner" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    },
    logoMikana: file(name: { eq: "logoMikana" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`