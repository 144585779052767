import React from "react"
import Img from "gatsby-image"
import PropTypes from "prop-types"

const Banner = ({ image, info }) => {
  return (
    <section className="container-fluid bg-white py-4 py-md-5 nav-spacer">
      <div className="row no-gutters">
        <div className="col-12 col-md-6 d-flex flex-column justify-content-center">
          <div>
            <p className="display-4 text-uppercase d-inline font-weight-bold bg-primary px-2">
              Sont-ils ce que
            </p>
          </div>
          <div>
            <p className="display-4 d-inline-block text-uppercase my-2 font-weight-bold bg-primary px-2">
              vous pensez?
            </p>
          </div>
          <div className="my-5">
            <p>
              {info}
            </p>
          </div>
        </div>
        <div className="col-12 col-md-4 offset-md-1">
          <Img fluid={image.childImageSharp.fluid} />
        </div>
      </div>
    </section>
  )
}

Banner.propTypes = {
  image: PropTypes.object.isRequired,
  info: PropTypes.string.isRequired
}

export default Banner