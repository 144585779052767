import React from "react"
import PropTypes from "prop-types"
import ProfileStories from "../global/stories"

const Stories = ({ title }) => {
  return (
    <div className="py-5">
      <section className="container-fluid bg-white pb-5">
        <div className="container text-center">
          <div className="row">
            <div className="col-12">
              <h3 className="text-uppercase font-weight-bold">
                {title}
              </h3>
            </div>
          </div>
        </div>
      </section>
      <ProfileStories/>
    </div>
  )
}

Stories.propTypes = {
  title: PropTypes.string.isRequired,
}

export default Stories