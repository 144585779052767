import React from "react"
import Img from "gatsby-image"

const Files = ({logoMikana}) => (
  <section className="container-fluid bg-white py-5">
    <div className="container text-center">
      <div className="row">
        <div className="col-12 mb-4">
          <a href={`http://v.calameo.com/?bkcode=0055561531918f2e0eb67&amp%3Bmode=mini&fbclid=IwAR1ORMLqlzjqcAM3yYFqPOZr5m0H83OQyh7PeerucL2U6tDTCVPIKfIEZqE`}
             target="_blank"
             rel="noopener noreferrer"
             className="btn btn-dark p-3 text-white bg-dark btn-outline-dark"
          >
            Téléchargez le livret complet sur les préjugés
          </a>
        </div>
        <p className="col-12 d-flex flex-column flex-md-row justify-content-md-center align-items-center">
          Livret réalisé en collaboration avec
          <div className="col-8 col-md-2">
            <Img fluid={logoMikana.childImageSharp.fluid} />
          </div>
        </p>
        <div className="col-12">
          Illustration de Meky&nbsp;Ottawa
        </div>
      </div>
    </div>
  </section>
)

export default Files