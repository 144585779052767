import React from "react"
import Img from "gatsby-image"
import PropTypes from "prop-types"

const List = ({ images, list }) => {
  return (
    <section className="container-fluid bg-white px-0 py-5">
      {list.map((prejudice, index) => (
        <div className="row no-gutters py-4" key={index}>
          <div className="col-12 col-md-6 bg-dark text-white d-flex align-items-center py-3 py-md-0">
           <div className="col-12 col-md-8 offset-md-2" style={{maxWidth: '1000px'}}>
             <h3 className="py-3 text-uppercase">
               {prejudice.title}
             </h3>
             <p>{prejudice.info}</p>
           </div>
          </div>
          <div className="d-none d-md-block col-md-4 offset-md-1">
            {images[index] ? (
              <Img fluid={images[index].node.childImageSharp.fluid} />
            ) : ''}
          </div>
          <div className="col-12 py-3 d-md-none bg-dark">
            {images[index] ? (
              <Img fluid={images[index].node.childImageSharp.fluid} />
            ) : ''}
          </div>
        </div>
      ))}
    </section>
  )
}

List.propTypes = {
  images: PropTypes.array.isRequired,
  list: PropTypes.arrayOf(PropTypes.shape({
    info: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired
  })).isRequired,
}

export default List